
.totalstudenttext{
    color: #3867d6;
}
.totalstudentbottombar{
    background-color: #3867d6;
    height: 20px;
    margin-top: 8%;
    /* width: 111%;
   
    margin-left: -5%; */
}
.main{
    display: flex;
    flex-direction: column;
}

.react-datepicker{
    background-color: #484863 !important;
}
.custom-td-txt{
    cursor: pointer;
}
.custom-td-txt.disabled{
    cursor: not-allowed !important;
}

.custom-td-txt:hover{
    color: #3867d6 !important;
    
}
.custom-td-txt.disabled:hover{
    color: red !important;
}

.hyperlinkReport:hover{
    color: red !important;
    text-decoration: underline;
}

.red{
    color: red;
}
/* 
.custom-td-txt:hover::before{
    content: attr(data);
} */


@media only screen and (max-width: 700px) {
     .custom-td-txt{
        color: #3867d6;
    }

    
    .custom-td-txt.disabled{
        color:red;
        cursor: not-allowed !important;
    }
    
}


@media only screen and (min-width: 700px) {
    #custom-responsive-table{
        max-width: 100%;
    }
    .threeDots{
    max-width: 250px;
    min-width: 100px;
} 
 
 .threeDots>p { 
    position: relative;
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    text-overflow:ellipsis;
    padding-left: 8px;
}
.threeDots>p:hover{
    text-overflow: inherit;
    white-space: normal;
    }
} 

.nodata{
    text-align: center;
    font-size: 1.5rem;
    color: grey;
    width: 100%;
    box-shadow: inset -5px -20px 7px rgb(154 154 204 / 10%);
}
